import { subMonths } from 'date-fns';
import { useCallback } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';

import { type DtoSendAnalyticsDigestRequest } from '@lp-lib/api-service-client/public';

import config from '../../../config';
import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { getFeatureQueryParam } from '../../../hooks/useFeatureQueryParam';
import { apiService } from '../../../services/api-service';
import { type Organization, type Organizer } from '../../../types';
import { err2s } from '../../../utils/common';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../ConfirmCancelModalContext/ModalWrapper';
import { OrganizerPicker } from '../../Organization/OrganizerPicker';

interface FormData {
  startTime: Date;
  endTime: Date;
  recipient: Organizer | null;
}

function SendAnalyticsDigestModal(
  props: triggerSendAnalyticsDigestModalProps & {
    onClose: () => void;
    onConfirm: () => void;
  }
) {
  const { organization, onClose, onConfirm } = props;

  const { control, handleSubmit, formState, getValues } = useForm<FormData>({
    defaultValues: {
      startTime: subMonths(new Date(), 1),
      endTime: new Date(),
      recipient: null,
    },
  });

  const {
    call,
    state: { error },
  } = useLiveAsyncCall(async (req: DtoSendAnalyticsDigestRequest) => {
    return await apiService.promotion.sendAnalyticsDigest(req);
  });

  const onSubmit = handleSubmit(async (data: FormData) => {
    if (!data.recipient) return;

    const resp = await call({
      orgId: organization.id,
      startTime: data.startTime.toJSON(),
      endTime: data.endTime.toJSON(),
      limit: 0,
      recipientId: data.recipient.uid,
      isDebug: getFeatureQueryParam('promotion-debug'),
    });
    if (resp) {
      onConfirm();
    }
  });

  return (
    <ModalWrapper
      borderStyle='gray'
      onClose={onClose}
      containerClassName='w-160'
    >
      <form className='w-full px-5 py-7.5 text-white' onSubmit={onSubmit}>
        <header className='text-2xl text-center font-medium'>
          Analytics Digest
        </header>

        <main className='mt-10 flex flex-col gap-7.5'>
          <p className='text-center'>
            It will send the analytics digest of{' '}
            <strong>{organization.name}</strong> to your Slack channel.
          </p>

          <div>
            <h3 className='text-base font-bold mb-1'>Start Date</h3>

            <Controller
              control={control}
              name='startTime'
              rules={{
                required: true,
                validate: (v) => {
                  return v < getValues('endTime');
                },
                deps: ['endTime'],
              }}
              render={({ field: { onChange, value } }) => (
                <ReactDatePicker
                  className={`${
                    formState.errors.startTime ? 'field-error' : 'field'
                  } h-15 mb-0`}
                  selected={value}
                  onChange={onChange}
                  selectsStart
                  showTimeInput
                  dateFormat='eee, MMM do yyyy h:mm aa'
                  placeholderText='N/A'
                />
              )}
            />
          </div>

          <div>
            <h3 className='text-base font-bold mb-1'>End Date</h3>

            <Controller
              control={control}
              name='endTime'
              rules={{
                required: true,
                validate: (v) => {
                  return v > getValues('startTime');
                },
                deps: ['startTime'],
              }}
              render={({ field: { onChange, value } }) => (
                <ReactDatePicker
                  className={`${
                    formState.errors.endTime ? 'field-error' : 'field'
                  } h-15 mb-0`}
                  selected={value}
                  onChange={onChange}
                  selectsStart
                  showTimeInput
                  dateFormat='eee, MMM do yyyy h:mm aa'
                  placeholderText='N/A'
                />
              )}
            />
          </div>

          <div>
            <h3 className='text-base font-bold mb-1'>Send to</h3>

            <Controller
              control={control}
              name='recipient'
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <OrganizerPicker
                  orgId={config.misc.lunaParkOrgId}
                  onChange={onChange}
                  defaultOrganizer={value || undefined}
                  placeholder='Select a member in Luna Park organization'
                  className='h-15'
                />
              )}
            />
          </div>
        </main>

        <footer className='mt-10 relative'>
          <div className='absolute w-full left-0 bottom-full mb-1 text-center text-red-500 text-sm'>
            {err2s(error)}
          </div>
          <div className='flex justify-center items-center gap-5'>
            <button
              type='button'
              onClick={onClose}
              className='btn-secondary w-40 h-10'
            >
              Cancel
            </button>

            <button
              type='submit'
              className='btn-primary w-40 h-10 flex justify-center items-center'
              disabled={formState.isSubmitting}
            >
              {formState.isSubmitting ? 'Sending...' : 'Send'}
            </button>
          </div>
        </footer>
      </form>
    </ModalWrapper>
  );
}

export interface triggerSendAnalyticsDigestModalProps {
  organization: Organization;
}

export function useTriggerSendAnalyticsDigestModal(): (
  props: triggerSendAnalyticsDigestModalProps
) => void {
  const triggerConfirmCancelModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    (props: triggerSendAnalyticsDigestModalProps) =>
      triggerConfirmCancelModal({
        kind: 'custom',
        element: (p) => (
          <SendAnalyticsDigestModal
            {...props}
            onClose={p.internalOnCancel}
            onConfirm={p.internalOnConfirm}
          />
        ),
      }),
    [triggerConfirmCancelModal]
  );
}
