import { Navigate, Outlet, Route, Routes } from '@remix-run/react';
import { useTitle } from 'react-use';

import { OrganizationDetailsTasks } from '../../components/Onboarding/OrganizationDetailsTasks';
import {
  OrganizationList,
  OrgFeatureProvider,
} from '../../components/Organization';
import {
  OrganizationDetailsGeneral,
  OrganizationDetailsLayout,
  OrganizationDetailsMembers,
  useOrgContext,
} from '../../components/Organization/Details';
import {
  OrganizationAnalyticsOverall,
  OrganizationAnalyticsProgramDetails,
  OrganizationAnalyticsProgramRoundDetails,
  OrganizationAnalyticsPrograms,
  OrganizationAnalyticsSessions,
} from '../../components/Organization/Details/Analytics';
import {
  OrganizationDetailsChannels,
  OrganizationDetailsPrograms,
} from '../../components/Organization/Details/Channel';
import { OrganizationDetailsSessions } from '../../components/Organization/Details/Sessions';
import { OrgEngagementScoreReport } from '../../components/Organization/OrgEngagementScoreReport';
import { PairingList } from '../../components/Pairing';
import {
  OrgRoundParticipationDetail,
  PairingRoundDetailsLayout,
  PairingRoundListInOrgDetails,
} from '../../components/Pairing/Round';
import { getFeatureQueryParamNumber } from '../../hooks/useFeatureQueryParam';
import { makeTitle } from '../../utils/common';
import { AdminView } from './AdminView';

function OrgFeatureWrapper() {
  const { org } = useOrgContext();
  return (
    <OrgFeatureProvider
      org={org}
      routePrefix={`/admin/organizations/${org.id}`}
      adminMode
    >
      <Outlet />
    </OrgFeatureProvider>
  );
}

// eslint-disable-next-line import/no-default-export
export default function OrganizationRoute(): JSX.Element {
  useTitle(makeTitle('Organizations'));

  return (
    <AdminView notificationCenter>
      <Routes>
        <Route index element={<Navigate to={'list'} replace></Navigate>} />
        <Route path='list' element={<OrganizationList />} />
        <Route path='analytics' element={<OrganizationList />} />
        <Route
          path='engagement-score-reports'
          element={<OrgEngagementScoreReport />}
        />
        <Route path=':orgId' element={<OrganizationDetailsLayout />}>
          <Route index element={<Navigate to={'general'} replace></Navigate>} />
          <Route path='general' element={<OrganizationDetailsGeneral />} />
          <Route
            path='members'
            element={
              <OrganizationDetailsMembers
                className='pl-8 pt-10 pr-12.5'
                adminView
              />
            }
          />
          <Route
            path='sessions'
            element={
              <OrganizationDetailsSessions className='pl-8 pt-10 pr-12.5' />
            }
          />
          <Route path='tasks' element={<OrganizationDetailsTasks />} />
          <Route path='analytics' element={<OrgFeatureWrapper />}>
            <Route
              index
              element={
                <OrganizationAnalyticsOverall
                  exportEnabled
                  showPrograms
                  recentNSummaryDataPoints={getFeatureQueryParamNumber(
                    'analytics-summary-data-points'
                  )}
                />
              }
            />
            <Route
              path='sessions'
              element={<OrganizationAnalyticsSessions />}
            />
            <Route path='programs'>
              <Route index element={<OrganizationAnalyticsPrograms />} />
              <Route
                path=':programLinkId'
                element={<OrganizationAnalyticsProgramDetails />}
              />
              <Route
                path=':programLinkId/rounds/:roundId'
                element={<OrganizationAnalyticsProgramRoundDetails />}
              />
            </Route>
          </Route>
          <Route path='pairing-rounds'>
            <Route index element={<Navigate to={'list'} replace />} />
            <Route path='list' element={<PairingRoundListInOrgDetails />} />
            <Route path=':roundId' element={<PairingRoundDetailsLayout />}>
              <Route index element={<Navigate to={'details'} replace />} />
              <Route path='details' element={<PairingList />} />
              <Route
                path='participation-detail'
                element={<OrgRoundParticipationDetail />}
              />
            </Route>
          </Route>
          <Route path='channels/*' element={<OrganizationDetailsChannels />} />
          <Route path='programs/*' element={<OrganizationDetailsPrograms />} />
        </Route>
      </Routes>
    </AdminView>
  );
}
