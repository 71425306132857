export const PAIRING_TEAM_EMOJIS = [
  ':monkey_face:',
  ':gorilla:',
  ':cat:',
  ':dog:',
  ':wolf:',
  ':fox_face:',
  ':raccoon:',
  ':lion_face:',
  ':tiger:',
  ':unicorn_face:',
  ':cow::pig:',
  ':sheep:',
  ':mouse:',
  ':bear:',
  ':panda_face:',
  ':rabbit:',
  ':chicken:',
  ':hatched_chick:',
  ':bird:',
  ':swan:',
  ':parrot:',
  ':dolphin:',
  ':frog:',
  ':tropical_fish:',
  ':snake:',
  ':ladybug:',
  ':penguin:',
  ':bee:',
  ':shark:',
  ':cherry_blossom:',
  ':sunflower:',
  ':evergreen_tree:',
  ':maple_leaf:',
  ':shamrock:',
  ':grapes:',
  ':watermelon:',
  ':lemon:',
  ':peach:',
  ':strawberry:',
  ':cherries:',
  ':avocado:',
  ':garlic:',
  ':pretzel:',
  ':cheese_wedge:',
  ':hamburger:',
  ':popcorn:',
  ':croissant:',
  ':dumpling:',
  ':fortune_cookie:',
  ':yellow_heart:',
  ':boom:',
  ':ghost:',
  ':smiling_imp:',
  ':male_fairy::skin-tone-3:',
  ':doughnut:',
  ':icecream:',
  ':cupcake:',
  ':beer:',
  ':wine_glass:',
  ':earth_americas:',
  ':airplane:',
  ':ferris_wheel:',
  ':helicopter:',
  ':rocket:',
  ':crescent_moon:',
  ':sun_with_face:',
  ':star:',
  ':snowflake:',
  ':fire:',
  ':v::skin-tone-3:',
  ':rainbow:',
  ':zap:',
  ':snowman_without_snow:',
  ':ocean:',
  ':umbrella_on_ground:',
  ':jack_o_lantern:',
  ':balloon:',
  ':trophy:',
  ':table_tennis_paddle_and_ball:',
  ':game_die:',
  ':diamonds:',
  ':crown:',
  ':gem:',
  ':guitar:',
  ':musical_note:',
  ':mag:',
  ':pushpin:',
  ':crossed_swords:',
  ':magnet:',
  ':sweat_drops:',
  ':alien:',
  ':cupid:',
  ':eyes:',
  ':santa::skin-tone-3:',
  ':palm_tree:',
  ':tornado:',
  ':blue_heart:',
  ':purple_heart:',
  ':white_heart:',
];
