import { useState } from 'react';
import { type SingleValue } from 'react-select';
import AsyncSelect from 'react-select/async';

import { type DtoChannelMember } from '@lp-lib/api-service-client/public';

import { useInstance } from '../../hooks/useInstance';
import { apiService } from '../../services/api-service';
import { buildReactSelectStyles } from '../../utils/react-select';

type ChannelMemberPickerProps = {
  channelId: string;
  onChange?: (value: DtoChannelMember) => void;
  filter?: (members: DtoChannelMember[]) => DtoChannelMember[];
  autoFocus?: boolean;
};

export function ChannelMemberPicker(
  props: ChannelMemberPickerProps
): JSX.Element | null {
  const { channelId, filter } = props;
  const styles = useInstance(() => buildReactSelectStyles<DtoChannelMember>());
  const [selected, setSelected] =
    useState<SingleValue<DtoChannelMember> | null>(null);

  const loadOptions = async (q: string): Promise<DtoChannelMember[]> => {
    const resp = await apiService.channel.getMembers(channelId);
    const kw = q.startsWith('#') ? q.substring(1) : q;
    const members = filter ? filter(resp.data.members) : resp.data.members;
    return members.filter(
      (c) =>
        c.status === 'active' &&
        !!c.uid &&
        c.fullName.toLowerCase().includes(kw.toLowerCase())
    );
  };

  const handleSingleChange = (option: SingleValue<DtoChannelMember>) => {
    setSelected(option);
    if (!props.onChange) return;
    if (option) {
      props.onChange(option);
    }
  };

  return (
    <AsyncSelect<DtoChannelMember, false>
      autoFocus={props.autoFocus}
      styles={styles}
      cacheOptions
      loadOptions={loadOptions}
      defaultOptions
      classNamePrefix='select-box-v2'
      value={selected}
      noOptionsMessage={(obj) => {
        if (!obj.inputValue) return 'Start typing to search';
        return 'No members matched';
      }}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.fullName}
      onChange={handleSingleChange}
    />
  );
}
