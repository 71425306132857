import { useCallback, useMemo, useState } from 'react';

import { type DtoChannelMember } from '@lp-lib/api-service-client/public';

import { useAsyncCall } from '../../hooks/useAsyncCall';
import {
  apiService,
  type CreatePairingPlayerRequest,
} from '../../services/api-service';
import {
  type Organization,
  type Organizer,
  type Pairing,
  type PairingPlayer,
  PairingPlayerUtils,
} from '../../types';
import { err2s } from '../../utils/common';
import { type Action, ActionSheet } from '../ActionSheet';
import { ChannelMemberPicker, ChannelUtils } from '../Channel';
import { CopyButton } from '../common/CopyButton';
import { Modal } from '../common/Modal';
import { AccountIcon } from '../icons/AccountIcon';
import { DeleteIcon } from '../icons/DeleteIcon';
import { OrganizerPicker } from '../Organization/OrganizerPicker';

function PickPlayerFromOrg(props: {
  pairing: Pairing;
  org: Organization;
  excludedUids: string[];
  onPick: (playerId: string | null) => void;
}): JSX.Element {
  const { org, excludedUids, onPick } = props;

  const filterOrganizers = useCallback(
    (organizers: Organizer[]) => {
      return organizers.filter((organizer) => {
        if (organizer.pairingDisabled) return false;
        return excludedUids.findIndex((u) => u === organizer.uid) === -1;
      });
    },
    [excludedUids]
  );

  return (
    <OrganizerPicker
      orgId={org.id}
      onChange={(organizer: Organizer) => onPick(organizer.uid)}
      filterOrganizers={filterOrganizers}
      autoFocus
    />
  );
}

function PickPlayerFromChannel(props: {
  pairing: Pairing;
  org: Organization;
  excludedUids: string[];
  onPick: (memberId: Nullable<string>) => void;
}): JSX.Element | null {
  const { pairing, excludedUids, onPick } = props;

  const channelId = pairing.round?.channel?.id;

  const filter = useCallback(
    (members: DtoChannelMember[]) => {
      return members.filter((player) => {
        return excludedUids.findIndex((u) => u === player.uid) === -1;
      });
    },
    [excludedUids]
  );

  if (!channelId) return null;

  return (
    <ChannelMemberPicker
      channelId={channelId}
      onChange={(member: DtoChannelMember) => onPick(member.uid)}
      filter={filter}
      autoFocus
    />
  );
}

function PairingPlayerAddModal({
  org,
  pairing,
  pairings,
  onCancel,
  onAdded,
}: {
  org: Organization;
  pairing: Pairing;
  pairings: Pairing[];
  onCancel: () => void;
  onAdded: (pairing: Pairing) => void;
}): JSX.Element {
  const {
    state: { transformed: state },
    error,
    call,
  } = useAsyncCall(
    useCallback(
      (roundId: string, pairingId: string, req: CreatePairingPlayerRequest) => {
        return apiService.pairing.addPlayer(roundId, pairingId, req);
      },
      []
    )
  );

  const channel = pairing.round?.channel;

  const PlayerPicker = !!channel ? PickPlayerFromChannel : PickPlayerFromOrg;

  const pickFrom = channel ? ChannelUtils.ChannelName(channel) : org.name;

  const [playerUid, setPlayerUid] = useState<Nullable<string>>(null);

  const handleSave = async () => {
    if (!playerUid) return;

    const resp = await call(pairing.roundId, pairing.id, {
      uid: playerUid,
    });
    if (!resp) return;
    onAdded(resp.data.pairing);
  };

  const joinedUids = useMemo(() => {
    return pairings
      .map((p) => p.players)
      .flat()
      .filter((p): p is PairingPlayer => !!p)
      .map((p) => p.uid);
  }, [pairings]);

  return (
    <Modal borderStyle='white'>
      <div className='w-160 px-5 py-10 flex flex-col items-center gap-8'>
        <div className='text-center'>
          <h1 className='text-2xl font-medium'>
            Add Player from <br />
            {pickFrom}
          </h1>
        </div>

        <div className='w-full'>
          <p className='font-bold mb-1'>Member</p>
          <PlayerPicker
            pairing={pairing}
            org={org}
            onPick={setPlayerUid}
            excludedUids={joinedUids}
          />
        </div>

        <div className='flex-shrink-0 flex flex-col gap-1'>
          {error && (
            <div className='w-full text-center text-3xs text-red-005'>
              Saving Failed. Err Msg: {err2s(error)}
            </div>
          )}
          <div className='flex justify-center items-center gap-4'>
            <button
              type='button'
              className='btn-secondary w-40 h-10'
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type='button'
              className='btn-primary w-40 h-10'
              onClick={handleSave}
              disabled={state.isRunning || !playerUid}
            >
              {state.isRunning ? 'Adding' : 'Confirm'}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const PairingPlayerActionSheet = ({
  pairing,
  player,
  onMakeOrganizer,
  onDelete,
}: {
  pairing: Pairing;
  player: PairingPlayer;
  onMakeOrganizer: () => void;
  onDelete: () => void;
}): JSX.Element => {
  const actions: Action<string>[] = [];

  if (pairing.organizerUid !== player.uid) {
    actions.push({
      kind: 'button',
      key: 'update-organizer-role',
      icon: <AccountIcon />,
      text: 'Make Organizer',
      onClick: onMakeOrganizer,
    });
  }

  actions.push({
    kind: 'button',
    key: 'delete',
    icon: <DeleteIcon />,
    text: 'Remove Player',
    className: 'text-red-002',
    onClick: onDelete,
  });

  return <ActionSheet actions={actions} />;
};

export const PairingDetails = (props: {
  org: Organization;
  allPairings: Pairing[];
  pairing: Pairing;
  onDeleted: (pairing: Pairing) => void;
  onUpdated: (pairing: Pairing) => void;
}): JSX.Element => {
  const { pairing, onDeleted, onUpdated, org, allPairings } = props;

  const [addingPlayerPairing, setAddingPlayerPairing] =
    useState<boolean>(false);

  const handleDeletePairing = async () => {
    const resp = await apiService.pairing.deletePairing(
      pairing.roundId,
      pairing.id
    );
    if (!resp) return;
    onDeleted(pairing);
  };

  const handleDeletePlayer = async (player: PairingPlayer) => {
    const resp = await apiService.pairing.removePlayer(
      pairing.roundId,
      pairing.id,
      player.uid,
      true
    );
    if (!resp) return;
    onUpdated(resp.data.pairing);
  };

  const handleMakeOrganizer = async (player: PairingPlayer) => {
    const resp = await apiService.pairing.updatePairing(
      pairing.roundId,
      pairing.id,
      {
        organizerUid: player.uid,
      }
    );
    if (!resp) return;
    onUpdated(resp.data.pairing);
  };

  return (
    <div className='relative w-full h-full bg-black border border-secondary rounded-xl p-2.5 flex flex-col gap-2'>
      {(!pairing.players || pairing.players.length === 0) && (
        <button
          type='button'
          onClick={handleDeletePairing}
          className='absolute right-2.5 top-2.5 w-10 h-10 border border-secondary rounded-xl btn flex justify-center items-center'
        >
          <DeleteIcon className='w-3.5 h-3.5 fill-current text-red-002' />
        </button>
      )}

      <div className='font-bold'>{pairing.teamName || 'N/A'}</div>

      {pairing.players?.map((player) => (
        <div
          key={player.uid}
          className='w-full flex items-center justify-between whitespace-nowrap'
        >
          <div className='flex-shrink flex items-center gap-2'>
            <div className='flex-shrink-0'>
              <PairingPlayerActionSheet
                pairing={pairing}
                player={player}
                onDelete={() => handleDeletePlayer(player)}
                onMakeOrganizer={() => handleMakeOrganizer(player)}
              />
            </div>
            <div className='flex-1'>
              <CopyButton
                copiedText={player.organizer?.email || 'N/A'}
                promptText='Email copied'
              >
                {PairingPlayerUtils.GetDisplayName(player)}
              </CopyButton>
            </div>
            {pairing.organizerUid === player.uid && (
              <div className=' flex-shrink-0'>(Organizer)</div>
            )}
          </div>
          {player.isGameCompleted && <div className='flex-shrink-0'>✓</div>}
        </div>
      ))}

      <div>
        <button
          type='button'
          onClick={() => setAddingPlayerPairing(true)}
          className='btn font-medium text-primary'
        >
          + Add Player
        </button>
      </div>

      {addingPlayerPairing && (
        <PairingPlayerAddModal
          org={org}
          pairings={allPairings}
          pairing={pairing}
          onCancel={() => {
            setAddingPlayerPairing(false);
          }}
          onAdded={(pairing: Pairing) => {
            onUpdated(pairing);
            setAddingPlayerPairing(false);
          }}
        />
      )}
    </div>
  );
};
