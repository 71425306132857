import capitalize from 'lodash/capitalize';
import shuffle from 'lodash/shuffle';
import { useMemo } from 'react';

import {
  type Organization,
  type Organizer,
  type Pairing,
  type PairingRound,
  PairingRoundUtils,
  PairingUtils,
} from '../../types';
import { joinNames } from '../../utils/string';
import { type MessageVariableMapping } from '../MessageTemplate/types';
import { useOrgOwner } from '../Organization';
import { PAIRING_TEAM_EMOJIS } from './PairingTeamEmojis';

function getDisplayName(organizer: Organizer | null): string {
  if (!organizer) return 'N/A';
  if (organizer.firstName) return organizer.firstName;

  return capitalize(organizer.email.split('@')[0]);
}

function formatPairingPlayersNames(pairing: Pairing | null): string {
  if (!pairing || !pairing.players || pairing.players.length === 0)
    return 'N/A';
  return joinNames(pairing.players.map((p) => getDisplayName(p.organizer)));
}

export function usePairingMessageTemplateVars(props: {
  org: Organization;
  round: PairingRound;
  pairings: Pairing[];
}): MessageVariableMapping[] {
  const { org, round, pairings } = props;

  const orgOwner = useOrgOwner(org.id);

  const roundVars = useMemo(() => {
    const orderedPairing = pairings.sort(
      (a, b) => (b?.score ?? 0) - (a?.score ?? 0)
    );
    const totalPlayer = pairings
      .map((p) => p.players?.length || 0)
      .reduce((sum, a) => sum + a, 0);

    const teamNames = pairings
      .map((p) => p.teamName)
      .filter((name) => !!name)
      .sort((a, b) => (a || '')?.localeCompare(b || ''));
    const emojis = shuffle(
      Array(Math.ceil(teamNames.length / PAIRING_TEAM_EMOJIS.length))
        .fill([...PAIRING_TEAM_EMOJIS])
        .reduce((a, b) => a.concat(b), [])
    );

    const teamNameListWithEmojis =
      teamNames.map((name, index) => `${emojis[index]} ${name}`).join('\n') ||
      'N/A';

    return {
      organizationName: org.name,
      billingAdminName: getDisplayName(orgOwner),
      recipientName: getDisplayName(orgOwner),
      round: new Date(round.createdAt).toLocaleDateString('en-US', {
        month: 'long',
        year: 'numeric',
      }),
      startDate: new Date(round.startedAt).toLocaleDateString('en-US', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
      }),
      endDate: new Date(round.endedAt).toLocaleDateString('en-US', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
      }),
      winningPlayers: formatPairingPlayersNames(orderedPairing[0]),
      secondPlacePlayers: formatPairingPlayersNames(orderedPairing[1]),
      thirdPlacePlayers: formatPairingPlayersNames(orderedPairing[2]),
      ofTotalParticipants: String(totalPlayer),
      currentHighScore: String(orderedPairing[0]?.score || 'N/A'),
      teamNameListWithEmojis,
      leaderboardURL: PairingRoundUtils.GetLeaderboardURL(round),
      creatorDisplayName: getDisplayName(orgOwner),
      playerDisplayName: getDisplayName(orgOwner),
      channelName: '#lunapark-channel',
      loginLink: 'https://lunapark.com/user-join',
    };
  }, [org.name, orgOwner, pairings, round]);

  return useMemo(() => {
    return pairings.map((pairing): MessageVariableMapping => {
      const organizer = PairingUtils.GetOrganizer(pairing)?.organizer || null;
      return {
        ...roundVars,
        pairingPlayers: formatPairingPlayersNames(pairing),
        gameURL: PairingUtils.GetGameURL(pairing),
        organizerName: getDisplayName(organizer),
        score: String(pairing.mainSession?.maxScore ?? 0),
        teamName: pairing.teamName || 'N/A',
      };
    });
  }, [pairings, roundVars]);
}
