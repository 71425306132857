import { ErrorMessage } from '@hookform/error-message';
import { subMonths } from 'date-fns';
import ReactDatePicker from 'react-datepicker';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';

import { type DtoGenerateEngagementScoreReportRequest } from '@lp-lib/api-service-client/public';

import { apiService } from '../../services/api-service';
import { NotificationType } from '../../types';
import { uuidv4 } from '../../utils/common';
import { CollapsibleSection } from '../common/CollapsibleSection';
import { DeleteIcon } from '../icons/DeleteIcon';
import { Loading } from '../Loading';
import { useNotificationDataSource } from '../Notification/Context';
import { useUser } from '../UserContext';
import { OrganizationSelect } from './OrganizationSelect';

type FormData = Omit<
  DtoGenerateEngagementScoreReportRequest,
  'startedAt' | 'endedAt'
> & {
  startedAt: Date;
  endedAt: Date;
  all: boolean;
};

function GameTypeMultipliers() {
  const { control, register } = useFormContext<FormData>();
  const { fields, append, remove } = useFieldArray<FormData>({
    control: control,
    name: 'params.gameTypeMultipliers',
  });

  return (
    <div className='flex flex-col gap-2'>
      <div className='flex items-center gap-2'>
        <label className='text-sms font-bold'>Game Types</label>
        <button
          type='button'
          className='text-primary text-xs underline'
          onClick={() =>
            append({
              type: '',
              value: 1,
            })
          }
        >
          Add
        </button>
      </div>
      {fields.map((f, index) => (
        <div key={f.id}>
          <div className='flex items-center justify-between'>
            <input
              className='w-40 h-10 field mb-0'
              type='text'
              {...register(`params.gameTypeMultipliers.${index}.type`, {
                required: true,
                valueAsNumber: true,
              })}
              placeholder='Game Type'
            />
            <input
              className='w-20 h-10 field mb-0'
              type='number'
              step='0.01'
              min={1}
              {...register(`params.gameTypeMultipliers.${index}.value`, {
                required: true,
              })}
            />
            <button
              type='button'
              className=' text-red-001'
              onClick={() => remove(index)}
            >
              <DeleteIcon />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

function CESParamsFields() {
  const { register } = useFormContext<FormData>();
  return (
    <div className='w-160 flex gap-4'>
      <CollapsibleSection title='Weights' defaultOpened={false}>
        <div className='flex items-center justify-between'>
          <label className='text-sms font-bold'>Reply to water cooler</label>
          <input
            className='w-30 h-10 field mb-0'
            type='number'
            step='0.01'
            {...register('params.replyToWaterCoolerPoints', {
              required: true,
              valueAsNumber: true,
            })}
          />
        </div>
        <div className='flex items-center justify-between'>
          <label className='text-sms font-bold'>Reply to celebrations</label>
          <input
            className='w-30 h-10 field mb-0'
            type='number'
            step='0.01'
            {...register('params.replyToCelebrationPoints', {
              required: true,
              valueAsNumber: true,
            })}
          />
        </div>
        <div className='flex items-center justify-between'>
          <label className='text-sms font-bold'>
            User receives ice cream (message)
          </label>
          <input
            className='w-30 h-10 field mb-0'
            type='number'
            step='0.01'
            {...register('params.recognitionReceivedByMessagePoints', {
              required: true,
              valueAsNumber: true,
            })}
          />
        </div>
        <div className='flex items-center justify-between'>
          <label className='text-sms font-bold'>
            User gives ice cream (message)
          </label>
          <input
            className='w-30 h-10 field mb-0'
            type='number'
            step='0.01'
            {...register('params.recognitionSentByMessagePoints', {
              required: true,
              valueAsNumber: true,
            })}
          />
        </div>
        <div className='flex items-center justify-between'>
          <label className='text-sms font-bold'>
            User recieves ice cream (Emoji)
          </label>
          <input
            className='w-30 h-10 field mb-0'
            type='number'
            step='0.01'
            {...register('params.recognitionReceivedByReactionPoints', {
              required: true,
              valueAsNumber: true,
            })}
          />
        </div>
        <div className='flex items-center justify-between'>
          <label className='text-sms font-bold'>
            User gives ice cream (Emoji)
          </label>
          <input
            className='w-30 h-10 field mb-0'
            type='number'
            step='0.01'
            {...register('params.recognitionSentByReactionPoints', {
              required: true,
              valueAsNumber: true,
            })}
          />
        </div>
        <div className='flex items-center justify-between'>
          <label className='text-sms font-bold'>
            User plays valid game session
          </label>
          <input
            className='w-30 h-10 field mb-0'
            type='number'
            step='0.01'
            {...register('params.gamePlayedPoints', {
              required: true,
              valueAsNumber: true,
            })}
          />
        </div>
        <div className='flex items-center justify-between'>
          <label className='text-sms font-bold'>
            User organizes valid game session
          </label>
          <input
            className='w-30 h-10 field mb-0'
            type='number'
            step='0.01'
            {...register('params.gameOrganizedPoints', {
              required: true,
              valueAsNumber: true,
            })}
          />
        </div>
      </CollapsibleSection>
      <CollapsibleSection title='Multiples' defaultOpened={false}>
        <div className='flex items-center justify-between'>
          <label className='text-sms font-bold'>Is Custom Game</label>
          <input
            className='w-30 h-10 field mb-0'
            type='number'
            step='0.01'
            min={1}
            {...register('params.ugcGameMultiplier', {
              required: true,
              valueAsNumber: true,
            })}
          />
        </div>
        <div className='flex items-center justify-between'>
          <label className='text-sms font-bold'>Is Live Game</label>
          <input
            className='w-30 h-10 field mb-0'
            type='number'
            step='0.01'
            min={1}
            {...register('params.liveGameMultiplier', {
              required: true,
              valueAsNumber: true,
            })}
          />
        </div>
        <div className='flex flex-col gap-2'>
          <label className='text-sms font-bold'>Game Length</label>
          <div className='flex items-center justify-between'>
            <label className='text-2xs'>Every X Secs</label>
            <input
              className='w-30 h-8 field mb-0'
              type='number'
              {...register('params.gameDurationMultiplier.everySecs', {
                required: true,
                valueAsNumber: true,
              })}
            />
          </div>
          <div className='flex items-center justify-between'>
            <label className='text-2xs'>Multiplier</label>
            <input
              className='w-30 h-8 field mb-0'
              type='number'
              step='0.01'
              min={1}
              {...register('params.gameDurationMultiplier.value', {
                required: true,
                valueAsNumber: true,
              })}
            />
          </div>
        </div>
        <GameTypeMultipliers />
      </CollapsibleSection>
    </div>
  );
}

export function OrgEngagementScoreReport() {
  const user = useUser();
  const form = useForm<FormData>({
    defaultValues: {
      startedAt: subMonths(new Date(), 3),
      endedAt: new Date(),
      all: false,
      summary: true,
      standard: true,
      breakdown: false,
      filterZero: true,
      paid: true,
      params: {
        replyToWaterCoolerPoints: 10,
        replyToCelebrationPoints: 5,
        recognitionReceivedByMessagePoints: 10,
        recognitionSentByMessagePoints: 10,
        recognitionReceivedByReactionPoints: 0.5,
        recognitionSentByReactionPoints: 0.5,
        gamePlayedPoints: 50,
        gameOrganizedPoints: 50,

        ugcGameMultiplier: 1.5,
        liveGameMultiplier: 1,
        gameDurationMultiplier: {
          everySecs: 600,
          value: 1.05,
        },
        gameTypeMultipliers: [
          {
            type: '🧊 Icebreakers',
            value: 1.1,
          },
          {
            type: '🌈 DEI',
            value: 1.1,
          },
        ],
      },
    },
  });

  const {
    control,
    watch,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = form;

  const all = watch('all');
  const { send } = useNotificationDataSource();

  const onSubmit = handleSubmit(async (data: FormData) => {
    if (!data.orgId && !data.all) {
      setError('orgId', {
        type: 'required',
        message: 'Organization is required',
      });
      return;
    }
    if (data.startedAt > data.endedAt) {
      setError('startedAt', {
        type: 'validate',
        message: 'Start Date must be before End Date',
      });
      return;
    }
    await apiService.organization.generateEngagementScoreReport({
      ...data,
      orgId: data.all ? undefined : data.orgId,
      debug: data.all ? false : data.debug,
      standard: data.standard,
      paid: data.all ? data.paid : false,
      startedAt: data.startedAt.toISOString(),
      endedAt: data.endedAt.toISOString(),
    });
    send({
      id: uuidv4(),
      toUserClientId: user.id,
      type: NotificationType.General,
      createdAt: Date.now(),
      metadata: {
        message: 'The report will be sent to Slack soon.',
      },
    });
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit}>
        <div className='text-white w-full h-full p-12'>
          <header className='flex justify-between items-center'>
            <h1 className='font-bold text-4xl'>Engagement Score Reports</h1>
          </header>

          <main className='w-full mt-7 flex flex-col gap-4'>
            <div>
              <div className='flex items-center gap-4'>
                <div className='w-50'>
                  <Controller<FormData, 'orgId'>
                    name='orgId'
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <div>
                          <OrganizationSelect
                            className='w-full'
                            orgId={all ? null : value ?? null}
                            onChange={(org) => onChange(org?.id)}
                            isClearable
                            noFakeOrg
                            isDisabled={all}
                          />
                        </div>
                      );
                    }}
                  />
                </div>
                <Controller<FormData, 'all'>
                  name='all'
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <label className='flex items-center justify-center gap-1 mt-1'>
                        <span className='text-white text-xl'>All</span>
                        <input
                          type='checkbox'
                          className='checkbox-dark w-6 h-6'
                          checked={value}
                          onChange={(e) => {
                            onChange(e.target.checked);
                            if (e.target.checked) {
                              form.setValue('breakdown', false);
                            }
                          }}
                        />
                      </label>
                    );
                  }}
                />
              </div>
              <ErrorMessage
                errors={errors}
                name='orgId'
                render={({ message }) => (
                  <p className='text-sms text-red-002'>{message}</p>
                )}
              />
            </div>
            <div>
              <div className='flex items-center gap-2'>
                <div className='w-50'>
                  <Controller
                    rules={{
                      required: true,
                    }}
                    control={control}
                    name='startedAt'
                    render={({ field: { onChange, value } }) => (
                      <ReactDatePicker
                        className={`field h-10 mb-0`}
                        selected={value}
                        onChange={onChange}
                        dateFormat='MMM do, yyyy'
                        placeholderText='Start Date'
                      />
                    )}
                  />
                </div>

                <div> To </div>

                <div className='w-50'>
                  <Controller
                    rules={{
                      required: true,
                    }}
                    control={control}
                    name='endedAt'
                    render={({ field: { onChange, value } }) => (
                      <ReactDatePicker
                        className={`field h-10 mb-0`}
                        selected={value}
                        onChange={onChange}
                        selectsEnd
                        dateFormat='MMM do, yyyy'
                        placeholderText='End Date'
                      />
                    )}
                  />
                </div>
              </div>
              <ErrorMessage
                errors={errors}
                name='startedAt'
                render={({ message }) => (
                  <p className='text-sms text-red-002'>{message}</p>
                )}
              />
            </div>
            <div className='flex items-center gap-4'>
              <Controller<FormData, 'filterZero'>
                name='filterZero'
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <label className='flex items-center justify-center gap-1'>
                      <span className='text-white text-sms'>
                        Remove Zero Scores
                      </span>
                      <input
                        type='checkbox'
                        className='checkbox-dark'
                        checked={value}
                        onChange={onChange}
                      />
                    </label>
                  );
                }}
              />
              <Controller<FormData, 'summary'>
                name='summary'
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <label className='flex items-center justify-center gap-1'>
                      <span className='text-white text-sms'>Org Summary</span>
                      <input
                        type='checkbox'
                        className='checkbox-dark'
                        checked={all ? true : value}
                        onChange={onChange}
                      />
                    </label>
                  );
                }}
              />
              {false && (
                <Controller<FormData, 'breakdown'>
                  name='breakdown'
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <label className='flex items-center justify-center gap-1'>
                        <span className='text-white text-sms'>
                          Org Breakdown
                        </span>
                        <input
                          type='checkbox'
                          className='checkbox-dark'
                          checked={value}
                          onChange={onChange}
                        />
                      </label>
                    );
                  }}
                />
              )}
              <Controller<FormData, 'standard'>
                name='standard'
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <label className='flex items-center justify-center gap-1'>
                      <span className='text-white text-sms'>
                        Daily Non-Cumulative Scores
                      </span>
                      <input
                        type='checkbox'
                        className='checkbox-dark'
                        checked={value}
                        onChange={onChange}
                      />
                    </label>
                  );
                }}
              />
              {all && (
                <Controller<FormData, 'paid'>
                  name='paid'
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <label className='flex items-center justify-center gap-1'>
                        <span className='text-white text-sms'>
                          Paid Orgs(new tabs)
                        </span>
                        <input
                          type='checkbox'
                          className='checkbox-dark'
                          checked={value}
                          onChange={onChange}
                        />
                      </label>
                    );
                  }}
                />
              )}
              <Controller<FormData, 'debug'>
                name='debug'
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <label className='flex items-center justify-center gap-1'>
                      <span className='text-white text-sms'>Debug Info</span>
                      <input
                        type='checkbox'
                        className='checkbox-dark'
                        checked={all ? false : value}
                        onChange={onChange}
                        disabled={all}
                      />
                    </label>
                  );
                }}
              />
            </div>
            <CESParamsFields />
            <div>
              <button
                className='w-50 h-10 btn-primary flex items-center justify-center gap-2'
                type='submit'
                disabled={isSubmitting}
              >
                {isSubmitting && <Loading text='' />}
                Export
              </button>
            </div>
          </main>
        </div>
      </form>
    </FormProvider>
  );
}
