import ChannelMgmt from '../../Channel';
import ProgramMgmt from '../../Program';
import { useOrgContext } from './Context';

export function OrganizationDetailsChannels(): JSX.Element {
  const { org } = useOrgContext();

  return (
    <div className='w-full h-full pl-8 pt-10 pr-12.5'>
      <ChannelMgmt
        org={org}
        routePrefix={`/admin/organizations/${org.id}`}
        adminMode
      />
    </div>
  );
}

export function OrganizationDetailsPrograms(): JSX.Element {
  const { org } = useOrgContext();

  return (
    <div className='w-full h-full pl-8 pt-10 pr-12.5'>
      <ProgramMgmt
        org={org}
        routePrefix={`/admin/organizations/${org.id}`}
        adminMode
      />
    </div>
  );
}
