import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useEffectOnce } from 'react-use';

import { useAsyncCall } from '../../../hooks/useAsyncCall';
import {
  apiService,
  type CreateOrganizationRequest,
} from '../../../services/api-service';
import { type Organization } from '../../../types';
import { err2s } from '../../../utils/common';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../ConfirmCancelModalContext/ModalWrapper';
import { Loading } from '../../Loading';

interface FormData {
  name: string;
}

function CreateOrganizationModal(props: {
  onClose: () => void;
  onConfirm: (organization: Organization) => void;
}): JSX.Element {
  const { onClose, onConfirm } = props;

  const { register, handleSubmit, formState, setFocus } = useForm<FormData>();

  const {
    state: { transformed: state },
    error,
    call,
  } = useAsyncCall(
    useCallback((req: CreateOrganizationRequest) => {
      return apiService.organization.createOrganization(req);
    }, [])
  );

  const onSubmit = handleSubmit(async (data: FormData) => {
    const resp = await call({
      name: data.name,
      subscriptionPlan: 'free',
    });
    if (resp) {
      onConfirm(resp.data.organization);
    }
  });

  useEffectOnce(() => {
    setFocus('name');
  });

  return (
    <ModalWrapper
      borderStyle='gray'
      onClose={onClose}
      containerClassName='w-160'
    >
      <div className='w-160 px-5 py-8 text-white flex flex-col justify-center'>
        <header className='w-full text-2xl text-center font-medium'>
          Create Organization
        </header>

        <form onSubmit={onSubmit} className='w-full mt-8'>
          <label className='font-bold text-base'>
            Organization Display Name
            <input
              {...register('name', { required: true, maxLength: 50 })}
              placeholder='Must be 1 to 50 characters'
              className={`${
                formState.errors.name ? 'field-error' : 'field'
              } my-1`}
            ></input>
            {formState.errors.name && (
              <div className='text-red-005 font-medium text-3xs'>
                Must be 1 to 50 characters
              </div>
            )}
          </label>

          <div className='w-full mt-2'>
            {error && (
              <div className='w-full text-center text-3xs text-red-005'>
                Saving Failed. Err Msg: {err2s(error)}
              </div>
            )}
            <div className='flex justify-center mt-3'>
              <button
                type='button'
                onClick={onClose}
                className='btn-secondary w-40 h-10 mx-2'
              >
                Cancel
              </button>

              <button
                type='submit'
                className='btn-primary w-40 h-10 mx-2 flex justify-center items-center'
                disabled={
                  state.isRunning || Object.keys(formState.errors).length > 0
                }
              >
                {state.isRunning && (
                  <Loading text='' containerClassName='mr-2' />
                )}
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </ModalWrapper>
  );
}

export interface triggerCreateOrganizationModalProps {
  onConfirm?: (organization: Organization) => void;
}

export function useTriggerCreateOrganizationModal(): (
  props: triggerCreateOrganizationModalProps
) => void {
  const triggerConfirmCancelModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    (props: triggerCreateOrganizationModalProps) =>
      triggerConfirmCancelModal({
        kind: 'custom',
        element: (p) => (
          <CreateOrganizationModal
            onClose={p.internalOnCancel}
            onConfirm={(organization) => {
              p.internalOnConfirm();
              if (props.onConfirm) props.onConfirm(organization);
            }}
          />
        ),
      }),
    [triggerConfirmCancelModal]
  );
}
