import { type LinkProps, NavLink } from '@remix-run/react';
import { Outlet, useParams } from '@remix-run/react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { EnumsExConnectType } from '@lp-lib/api-service-client/public';

import { useAsyncCall } from '../../../hooks/useAsyncCall';
import { apiService } from '../../../services/api-service';
import { type Organization } from '../../../types/organization';
import { err2s } from '../../../utils/common';
import { ScoreboardIcon } from '../../icons/ScoreboardIcon';
import { SettingIcon } from '../../icons/SettingIcon';
import { ShuffleIcon } from '../../icons/ShuffleIcon';
import { SlackIcon } from '../../icons/SlackIcon';
import { TeamIcon } from '../../icons/TeamIcon';
import { TeamsIcon } from '../../icons/TeamsIcon';
import { Loading } from '../../Loading';
import { OrgConnection } from '../OrgConnection';
import { OrgProvider, useOrg } from './Context';

export const OrganizationDetailsLayout = (): JSX.Element => {
  const { orgId } = useParams<'orgId'>();
  const {
    state: { transformed: state },
    error,
    call,
  } = useAsyncCall(
    useCallback((orgId: string) => {
      return apiService.organization.getOrganization(orgId);
    }, [])
  );

  const [org, setOrg] = useState<Organization | null>(null);
  useEffect(() => {
    if (!orgId) return;

    const init = async () => {
      const resp = await call(orgId);
      if (!resp) return;
      setOrg(resp.data.organization);
    };

    init();
  }, [call, orgId]);

  if (!state.isDone) {
    return <Loading containerClassName='my-8 text-white' />;
  }

  if (error) {
    return (
      <div className='my-8 text-white'>
        Get Organization Err: {err2s(error)}
      </div>
    );
  }

  if (!org) {
    return <div className='my-8 text-white'>Organization not found.</div>;
  }

  return (
    <OrgProvider initOrg={org}>
      <div className='w-full h-full text-white flex'>
        <div className='flex-shrink-0 w-68 h-full bg-modal pt-4 px-3 pb-3'>
          <NavBar />
        </div>

        <div className='flex-grow h-full bg-black overflow-y-auto scrollbar-hide'>
          <Outlet />
        </div>
      </div>
    </OrgProvider>
  );
};

interface Menu {
  key: string;
  title: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  to: LinkProps['to'];
}

function NavBar(): JSX.Element {
  const org = useOrg();

  const menus = useMemo(() => {
    const menus: Menu[] = [];
    menus.push(
      {
        key: 'general',
        title: 'General',
        icon: SettingIcon,
        to: './general',
      },
      {
        key: 'member',
        title: 'Members',
        icon: TeamIcon,
        to: './members',
      }
    );

    if (
      org.connection &&
      org.connection.type === EnumsExConnectType.ExConnectTypeTeams
    ) {
      menus.push({
        key: 'teams',
        title: 'Teams Channels',
        icon: TeamsIcon,
        to: './channels',
      });
    } else {
      menus.push({
        key: 'channel',
        title: 'Slack Channels',
        icon: SlackIcon,
        to: './channels',
      });
    }

    menus.push({
      key: 'session',
      title: 'Session Analytics',
      icon: ScoreboardIcon,
      to: './sessions',
    });

    menus.push({
      key: 'tasks',
      title: 'Task Analytics',
      icon: ScoreboardIcon,
      to: './tasks',
    });

    menus.push({
      key: 'analytics',
      title: 'Admin Analytics',
      icon: ScoreboardIcon,
      to: './analytics',
    });

    menus.push({
      key: 'pairing',
      title: 'Random Pairing',
      icon: ShuffleIcon,
      to: './pairing-rounds',
    });

    return menus;
  }, [org.connection]);

  return (
    <div className='w-full h-full flex flex-col'>
      <div className='w-full flex-shrink-0 text-sms font-normal truncate'>
        <NavLink to={'/admin/organizations/'} className='text-icon-gray'>
          Organizations
        </NavLink>{' '}
        / <NavLink to={'./'}>{org.name}</NavLink>
      </div>

      <div className='mt-4 flex-shrink-0 text-xl font-bold truncate'>
        {org.name}
      </div>

      <div className='mt-5 w-full flex-1 flex flex-col justify-between items-center'>
        <div className='w-full flex flex-col items-center'>
          {menus.map((menu) => (
            <NavLink
              key={menu.key}
              to={menu.to}
              className={({ isActive }) =>
                `w-full h-11 pl-4 flex items-center ${
                  isActive
                    ? 'bg-lp-gray-002 rounded-xl cursor-pointer border border-black'
                    : 'hover:btn-primary'
                }`
              }
            >
              <menu.icon className='w-5 h-5 fill-current' />
              <div className='ml-2 font-medium'>{menu.title}</div>
            </NavLink>
          ))}
        </div>

        <div className='w-full flex flex-col items-center'>
          <OrgConnection org={org} />
        </div>
      </div>
    </div>
  );
}
