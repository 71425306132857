import copy from 'copy-to-clipboard';
import { format } from 'date-fns';
import { ExportToCsv } from 'export-to-csv';
import capitalize from 'lodash/capitalize';
import useSWRMutation from 'swr/mutation';

import { type DtoOnboardingTask } from '@lp-lib/api-service-client/public';

import { apiService } from '../../services/api-service';
import { type Action, ActionSheet } from '../ActionSheet';
import { CopyIcon } from '../icons/CopyIcon';
import { ScoreboardIcon } from '../icons/ScoreboardIcon';
import { Loading } from '../Loading';
import { useOrgContext } from '../Organization/Details';
import { useOnboardingTasks } from './hooks';
import { OnboardingTaskUtils } from './utils';

function DownloadButton() {
  const { trigger: handleClick, isMutating } = useSWRMutation(
    `/onboarding/tasks/exporter`,
    async () => {
      const { organizations } = (
        await apiService.organization.getAllOrganizations()
      ).data;

      const rows = [];
      rows.push([
        'Org ID',
        'Org name',
        'Task',
        'Is Relevant',
        'Is Complete',
        'Completed At',
        'Is Skipped',
        'Skipped At',
        'Is Locked',
      ]);

      for (const organization of organizations) {
        const { tasks } = (
          await apiService.onboarding.listTasks(organization.id)
        ).data;
        for (const task of tasks) {
          rows.push([
            organization.id,
            organization.name,
            task.displayName,
            task.isRelevant,
            task.isCompleted,

            task.completedAt
              ? format(new Date(task.completedAt), 'MM/dd/yyyy hh:mm aa')
              : 'N/A',
            task.isSkipped,
            task.skippedAt
              ? format(new Date(task.skippedAt), 'MM/dd/yyyy hh:mm aa')
              : 'N/A',
            task.isLocked,
          ]);
        }
      }

      const exporter = new ExportToCsv({
        filename: 'Onboarding Tasks Analytics',
      });
      exporter.generateCsv(rows);
    }
  );

  return (
    <button
      type='button'
      onClick={() => handleClick()}
      className='btn text-sms font-normal underline flex justify-center items-center'
      disabled={isMutating}
    >
      {isMutating && (
        <Loading text='' containerClassName='mr-2' imgClassName='w-4 h-4' />
      )}
      Download as CSV
    </button>
  );
}

function TaskActionSheet(props: { task: DtoOnboardingTask }) {
  const { task } = props;

  const actions: Action<string>[] = [];
  actions.push({
    kind: 'button',
    key: 'copy-link',
    icon: <CopyIcon />,
    text: 'Copy Link',
    onClick: () => {
      copy(OnboardingTaskUtils.GetLink(task, { absolute: true }));
    },
  });

  return <ActionSheet actions={actions} />;
}

function TaskRow(props: { task: DtoOnboardingTask; action: JSX.Element }) {
  const { task, action } = props;
  return (
    <tr>
      <td>{task.displayName}</td>
      <td>{capitalize(String(task.isRelevant))}</td>
      <td>{capitalize(String(task.isCompleted))}</td>
      <td>
        {task.completedAt
          ? format(new Date(task.completedAt), 'MM/dd/yyyy hh:mm aa')
          : 'N/A'}
      </td>
      <td>{capitalize(String(task.isSkipped))}</td>
      <td>
        {task.skippedAt
          ? format(new Date(task.skippedAt), 'MM/dd/yyyy hh:mm aa')
          : 'N/A'}
      </td>
      <td>{capitalize(String(task.isLocked))}</td>
      <td className='pr-1.5'>
        <div className='flex flex-row-reverse items-center'>{action}</div>
      </td>
    </tr>
  );
}

export function OrganizationDetailsTasks(): JSX.Element {
  const { org } = useOrgContext();
  const { data: tasks, isLoading } = useOnboardingTasks(org.id);

  if (isLoading) return <Loading />;

  return (
    <div className={`w-full h-full text-white pl-8 pt-10 pr-12.5`}>
      <header className='w-full flex justify-between items-start'>
        <div className='flex items-center'>
          <ScoreboardIcon className='w-6.5 h-6.5 fill-current' />
          <div className='ml-2 font-medium text-2xl'>Task Analytics</div>
        </div>

        <DownloadButton />
      </header>

      <main className='w-full mt-10'>
        <table className='table w-full'>
          <thead>
            <tr>
              <th>Task</th>
              <th>Is Relevant</th>
              <th>Is Completed</th>
              <th>Completed At</th>
              <th>Is Skipped</th>
              <th>Skipped At</th>
              <th>Is Locked</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {tasks?.map((t) => (
              <TaskRow
                key={t.name}
                task={t}
                action={<TaskActionSheet task={t} />}
              />
            ))}
          </tbody>
        </table>
      </main>
    </div>
  );
}
